import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"

const Header = ({ siteTitle }) => (
  <header>
    <nav className="site-nav">
      <div className="site-nav-options">
      
        <ul className="site-nav-items">
          <li key={siteTitle} className="site-nav-item">
            <Link to="/" className="site-nav-item-logo site-nav-item-link">
              {siteTitle}
            </Link>
          </li>

          <li key="contact" className="site-nav-item">
            <Link to="/contact" className="site-nav-item-link">
              Contact Us
            </Link>
          </li>
        </ul>
      
      </div>
    </nav>
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
