import React from "react"

import Layout from "../components/layout"
import Hero from "../components/hero"
import SEO from "../components/seo"

const IndexPage = () => (
  <Layout>
    <SEO title="Variety Show Studios" keywords={[`variety show studios`, `galactic rex`, `games`, `mobile`, `applications`]} />
    <Hero />
    <section>
      <div className="site-overview-content">
        <p className="site-overview__title">
          At Variety Show Studios We Focus On Fun
        </p>
        <p className="site-overview__text">
          We believe in building games and creating content for everyone. We focus on building
          worlds and characters that bring out the kid in all of us. Having fun is always 
          our first priority.
        </p>
      </div>
    </section>
  </Layout>
)

export default IndexPage
