import React from "react"

const Footer = ({siteTitle}) => (
  <footer>
    <div className="footer-content">
      <div className="footer-copyright">
        <div className="footer-copyright__title">{siteTitle}</div>
        <div className="footer-copyright__copyright">&copy; 2019 Variety Show Studios LLC</div>
      </div>
    </div>
  </footer>
)

export default Footer