import React from "react"

import LinkButton from "./link-button"

import profileImage from "../assets/images/hero-profile.png"

const Hero = () => (
  <section className="site-hero">
    
      <div className="site-hero-content">
        <div className="site-hero-content__container">
          <div className="site-hero-call-to-action">
            <div>
              <h2 className="site-hero-title">Galactic Rex</h2>
              <p className="site-hero-body">
                Help Galactic Rex escape the clutches of evil and return to his 
                home planet in this epic space adventure. Coming soon for iOS.
              </p>
            </div>
            <LinkButton title="Find Out More" href="https://galacticrex.com" />
          </div>
          <div className="site-hero-profile">
            <img src={profileImage} alt="Galactic Rex"/>
          </div>
        </div>
      </div>
    
  </section>
)

export default Hero